<template>
    <div>
      <h2>Budget Categories</h2>
      <h3 class="center">Ready to Assign ${{convertMilliUnitsToCurrencyAmount(readyToAssignAmount).toFixed(2)}}</h3>
      <table class="center">
        <thead>
          <tr>
            <th>Name</th>
            <th>Budgeted Amount</th>
            <th>Activity</th>
            <th>Available Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="category in filteredCategories" :key="category.id">
            <td>{{ category.name }}</td>
            <td>${{convertMilliUnitsToCurrencyAmount(category.budgeted).toFixed(2)}}</td>
            <td>${{convertMilliUnitsToCurrencyAmount(category.activity).toFixed(2)}}</td>
            <td>${{convertMilliUnitsToCurrencyAmount(isNaN(category.available) ? category.budgeted : category.available).toFixed(2)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  const ynab = require("ynab");
  import {utils} from 'ynab';
  export default {
    name: 'BudgetCheck',
    data() {
      return {
        categories: [],
        excludedCategories: ['Uncategorized', 'Inflow: Ready to Assign', 'Unassigned Funds'],
        currencySymbol: '$'
      }
    },
    computed: {
    filteredCategories() {
      return this.categories.filter(category => {
        return !this.excludedCategories.includes(category.name);
        });
      },
    readyToAssignAmount() {
      const readyToAssignCategory = this.categories.find(category => category.name === 'Unassigned Funds');
      console.log(readyToAssignCategory);
      return readyToAssignCategory ? readyToAssignCategory.balance : 0;
      },
    },
    mounted() {
      const accessToken = process.env.VUE_APP_YNAB_API_KEY;
      const ynabAPI = new ynab.API(accessToken);
      const budgetId = process.env.VUE_APP_YNAB_BUDGET_ID;
      ynabAPI.categories.getCategories(budgetId).then(response => {
        this.categories = response.data.category_groups.flatMap(group => group.categories);
      }).catch(error => {
        console.log(error);
      });
    },
    methods: {
    // Now we can make this method available to our template
    // So we can format this milliunits in the correct currency format
    convertMilliUnitsToCurrencyAmount: utils.convertMilliUnitsToCurrencyAmount
    },
  }
  </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  margin: 0px 0 0;
}
a {
  color: #42b983;
}
tr:nth-child(even) {
    background-color: #f2f2f2;
}
tr {
  text-align: left;
}
table {
  width: 100%;
  margin: 20px 0 0;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
</style>
